const apiUrl =
 // "http://172.208.33.94/PROJECTS/Kipinn/websitesapis/backend/public/api/"; //Development
 // "https://ghar.tenantsync.in/backend/public/api/"; //Production
  "https://bookmyghar.co.in/backend/public/api/"; //Production

const Config = {
  ApiUrl: apiUrl,
};

export default Config;
